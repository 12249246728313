<template>
  <div class="line inline-block">
    <el-button size="small" @click="to" :loading="loading"> 批量编辑 </el-button>
    <EditForm
      ref="form"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :isAdd="false"
      :data="data"
      title="编辑国家/地区"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import EditForm from './editForm'
import { commonEheaderMixin } from '@/mixins'
import cloneDeep from 'lodash/cloneDeep'

export default {
  mixins: [commonEheaderMixin],
  components: { EditForm },
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      data: {},
      loading: false
    }
  },
  methods: {
    to() {
      const selectedData = this.sup_this.selectedData
      if (!selectedData.length) {
        this.$message({
          type: 'warning',
          message: '请先选择需要的数据，再进行此操作'
        })
        return
      }
      const countryIdList = [...new Set(selectedData.map(({ countryId }) => countryId))]
      const detail = cloneDeep(this.tableData).filter(({ countryId: id }) => countryIdList.includes(id))
      this.data = this.formatResult(detail)
      this.$nextTick(() => {
        this.showDialog()
      })
    },

    formatResult(results) {
      const tmpObj = {}
      results.map((item) => {
        const { countryName } = item
        if (!tmpObj[countryName]) {
          tmpObj[countryName] = []
        }
        tmpObj[countryName].push(item)
      })
      return tmpObj
    }
  }
}
</script>